module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/essai-opinion-matelas-emma-hybrid-premium","/essai-opinion-matelas-emma-hybrid-premium-airflow","/emma-original-vs-airflow","/essai-colchon-emma-original","/emma-hybrid-elite-essai","/guide-choisir-matelas-hotel","/sommier-a-lattes-senso-g-opinions","/sommier-alba-hypnia-opinions","/opinions-sommier-hypnia-essentiel","/sommier-tapissier-hypnia-original-opinion","/sommier-tapissier-hypnia-essentiel","/lit-coffre-emma-opinion","/lit-tapisse-emma-opinions","/canape-lit-emma-avantages","/deshumidificateur-lidl-controle-humidite","/climatiseur-portable-comfee-lidl-opinion","/meilleurs-canapes-lits-ikea","/opinion-humidificateurs-pour-mieux-dormir","/qu-est-ce-que-l-apnee-du-sommeil-traitements","/comment-nettoyer-matelas-astuces-conseils","/nettoyer-vomi-du-matelas","/comment-savoir-si-j-ai-besoin-d-un-matelas-orthopedique","/choisir-la-hauteur-du-matelas","/comment-choisir-base-de-lit","/reves-lucides-comment-les-avoir","/histoire-mousse-a-memoire","/meilleurs-matelas-pour-dormir-sur-le-cote","/mal-de-tete-au-reveil","/spm-et-qualite-du-sommeil-femme","/se-reveiller-avec-energie-au-son-du-reveil","/mal-dos-pendant-sommeil","/mieux-dormir-avec-cardiopathies","/recommandations-mieux-dormir-grossesse","/guide-pour-mieux-dormir-menopause","/mindfulness-meditation-sommeil-profond","/comment-prendre-melatonine-dormir","/que-faire-quand-vous-ne-pouvez-pas-dormir","/matelas-respirants","/comment-eliminer-acariens-matelas","/enlever-taches-urine-matelas","/comment-nettoyer-matelas-astuces-conseils","/sons-colores-pour-dormir","/remedes-maison-pour-bien-dormir","/therapie-cognitivo-comportementale-pour-l-insomnie","/meilleurs-matelas-pour-dormir-sur-le-ventre","/meilleure-position-pour-dormir-bien","/meilleurs-matelas-pour-couples","/comment-choisir-oreillers-en-fonction-de-votre-position-de-sommeil"],"excludePaths":[],"height":3,"prependToBody":false,"color":"#5598e5","footerHeight":500,"headerHeight":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
